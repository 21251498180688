import React, { Component } from 'react';
class Chrome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const axios = require('axios');
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.code !== undefined) {
            let code = parsed.code;
            let logintype = parsed.logintype;           
            axios.post('https://restapi.kanhasoftcrm.club/GoogleLoginUser/GoogleLogin/', { "code": code, "logintype": logintype },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    }
                }).then(response => {
                    console.log('with code = ', response);
                    let data = {
                        'error': response.data.error,
                        'notify_msg': response.data.message,
                        'data': response.data.data,
                        'is_crm': true
                    }
                    window.opener.postMessage(data, '*');
                    window.close();
                }).catch(error => {
                    console.log('Error fetching and parsing data', error);
                });
        } else {
            window.open('https://restapi.kanhasoftcrm.club/auth/google?logintype=' + this.props.match.params.type, '_self', 'height=500, width=500');
        }
    }
    render() {
        return (
            <>
            </>
        );
    }
}

export default Chrome;