import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Crome from "./crome";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <Router>
        {/* <Route path='/' exact component={SignIn} /> */}
        <Route exact path="/:type" component={Crome} />
        {/* <Route path="/google-login" component={GoogleLogins} />
        <Route path='/by-google-login' component={ByGoogleLogin} />
        <Route path='/sign-in' exact component={SignIn} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/email/confirm-email' component={CheckEmail} />
        <Route path='/password' component={UserCredential} /> */}
      </Router>
    );
  }
}


export default App
